import React, { useEffect } from "react";
import usercreate from "../../assets/usercreateicon.svg";
import userkaricon from "../../assets/userkaricon.svg";
import usermoneyicon from "../../assets/usermoneyicon.svg";
import stepsaftericon from "../../assets/stepsaftericon.svg";
import stepsbeforeicon from "../../assets/stepsbeforeicon.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const Sectionthree = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);
	return (
		<div data-aos="fade-up" className="bg-white font-raleway md:pt-24 pb-24 pt-7 motion-safe:animate-fadeIn">
			<div className="items-center justify-center text-center flex">
				<div className="md:w-[36%] w-[70%]">
					<h4 className="md:text-2xl text-xl text-sectionthreeheader font-bold">3 adımda kolayca yatırım yapmaya başlayın.</h4>
				</div>
			</div>
			<div className="md:flex md:mx-16 md:mt-20 mt-10">
				<section className="border-2 border-cardborder p-7 mx-3 rounded-lg">
					<img className="w-[33%]" src={usercreate} alt="icon" />
					<h3 className="text-sectionthreeheader font-bold mt-2 text-xl">Ücretsiz Bir Hesap Oluştur</h3>
					<p className="mt-3">Deneme hesabını açarak piyasaya bir adım daha yaklaş</p>
				</section>
				<section className="border-2 border-cardborder p-7 mx-3 rounded-lg md:mt-0 mt-10">
					<img className="w-[33%]" src={userkaricon} alt="icon" />
					<h3 className="text-sectionthreeheader font-bold mt-2 text-xl">Hesabına Para Ekle</h3>
					<p className="mt-3">Gerçek hesabını açarak piyasada sende kazanmaya başla.</p>
				</section>
				<section className="border-2 border-cardborder p-7 mx-3 rounded-lg md:mt-0 mt-10">
					<img className="w-[33%]" src={usermoneyicon} alt="icon" />
					<h3 className="text-sectionthreeheader font-bold mt-2 text-xl">Copy Trade</h3>
					<p className="mt-3">Zamanım yok, hesabım bana özel kalsın’’ diyorsan copy trade çalışmamız tam sana göre!</p>
				</section>
			</div>
		</div>
	);
};

export default Sectionthree;
