import React from "react";

const Index = () => {
	return (
		<div className="bg-black text-white p-10 pb-28">
			<p>
				Orea yatırım Gizlilik politikasıGizliliğiniz bizim için çok önemlidir. Kişisel verilerinizi korumayı ve bunlara saygı
				duymayı taahhüt ediyoruz. Bu Gizlilik Politikası, hizmetlerimizi kullanmayı seçtiğinizde hakkınızda ne tür kişisel veriler
				topladığımızı, kişisel verilerinizi nasıl kullanacağımızı, ne zaman ve kiminle paylaşacağımızı ve nasıl güvende tutacağımızı
				açıklar. Ayrıca, kişisel bilgilerinizin işlenmesi ve bunları nasıl kullanabileceğinizle ilgili haklarınızı da ayrıntılarıyla
				açıklar. Lütfen bu politikayı okumak ve anlamak için zaman ayırın. Bu Bildirimde zaman zaman değişiklik yapabiliriz ve bu
				Bildirimi güncellemeler için kontrol etmeniz önemlidir. Sahip olduğumuz tüm kişisel bilgiler, belirtilen zamanda geçerli
				gizlilik bildirimine tabi olacaktır. Önemli olduğunu düşündüğümüz değişiklikleri yaparsak, bunları size bildiririz. Bu
				bildirimin müşterilere ve potansiyel müşterilere yönelik olduğunu lütfen unutmayın. Bir Kuveyt Menkul çalışanı, Kuveyt
				Menkul'un yüklenicisi veya üçüncü taraf bir hizmet sağlayıcısıysanız, kişisel bilgileriniz iş sözleşmenizle, sözleşme
				ilişkinizle veya bizimle iletişime geçerek mevcut ayrı politikalarımıza uygun olarak kullanılacaktır. Bizimle iletişim
				kurmak için iletişim kanallarından herhangi birini kullanmak da dahil olmak üzere web sitelerimize erişerek, bu bildirimin
				şartlarını ve müşteri olmadan önce kişisel veriler de dahil olmak üzere bize ifşa ettiğiniz bilgileri nasıl okuduğumuzu ve
				anladığımızı düşünüyoruz. Bizimle bir hesap açtıktan sonra, herhangi bir değişiklik de dahil olmak üzere bu bildirimin,
				kişisel verilerinizi ve iş ilişkimiz sırasında ve feshinden sonra kişisel verilerinizi ve haklarınızı nasıl toplayacağımızı,
				sakladığımızı, kullandığımızı, paylaşacağımızı ve başka bir şekilde nasıl işlediğimizi belirleyeceğinizi kabul edersiniz.
				Who are we Kuveyt Menkul Limited (adress Khaled Ibn Al Waleed Street Al Dhow Tower, 13th Floor Sharq, Kuwait City) What Kind
				of Personal Information We Collect and Store Name, Surname and contact information • Date of birth and gender • Information
				about your income and wealth, including details about your assets and liabilities, account balances, spreadsheets, taxes and
				financial statements • Occupation and employment information • Location data • Knowledge and experience about trade, risk
				tolerance and risk profile • IP address, device features and other information about your trading experience • Bank account,
				e-wallets and credit card information • Details of your visits to our Website or Applications, including but not limited to
				traffic data, location data, blogs, and other communication data. How We Collect Your Personal Data Aşağıdaki durumlarda
				kişisel verilerinizi toplayabilir (veya alabiliriz) ve işleyebiliriz: (a) Web sitemizden, Uygulamalarımızdan veya başka bir
				yoldan (örneğin çevrimiçi formumuz aracılığıyla, e-posta, posta, faks veya telefon yoluyla) bizimle iletişime
				geçebilirsiniz. Örneğin, bir şikayet gönderirseniz, hizmetlerimizle veya Web Sitelerimizle veya Uygulamalarımızla ilgili bir
				sorun bildirin veya satış ekibimizle, teknik destekle veya şirketimizdeki herhangi bir departmanla başka bir şekilde irtibat
				kurun. İşlem hesabınızı açtığınızda veya adınız, e-posta adresiniz, ülkeniz, şifreniz vb. (b) sizden yanıt vermek zorunda
				olmasanız da, araştırma amaçlı kullandığımız anketleri doldurmanızı istiyoruz; www.kuveytmenkul.com (c) Cihazınızın
				üreticisi ve modeli, IP adresi, tarayıcı türü ve sürümü, saat dilimi ayarı, tarayıcı eklentisi türleri ve sürümleri, işletim
				sistemi, web tarayıcısı, platform, mobil operatör de dahil olmak üzere Web Sitemizi veya Uygulamalarımızı kullanmanız ve
				bunlarla etkileşimde bulunmanız ve İSS'niz. Web Sitemize veya Uygulamalarımıza (trafik verileri, konum verileri, web
				günlükleri ve diğer iletişim verileri dahil ancak bunlarla sınırlı olmamak üzere) yaptığınız ziyaretlerin ayrıntılarını
				toplayabiliriz. Bunu e-posta ve web sitesi çerezleri ve Web Sitelerimize ve Uygulamalarımıza yerleştirilmiş benzer izleme
				teknolojisi ile yapıyoruz. Size nasıl kullandığımız hakkında daha ayrıntılı bilgi vermek için Web Sitelerimizin ve
				Uygulamalarımızın her birinde çerez politikaları sunuyoruz; (d) platform teknolojimizi ve diğer özellikleri ve işlevleri
				kullanmak için alım satım hesabınıza giriş yaparsanız; (e) size sağladığımız çevrimiçi ticaret ürünlerini kullanırsanız. Bu
				ayrıntıları, sunduğumuz hizmetler bağlamında bu bilgileri bilmeleri gerekenler dışında herhangi bir üçüncü tarafa ifşa
				etmediğimizi lütfen unutmayın; veya (f) “beğen” düğmeleri ve sosyal medya platformları tarafından sunulan benzer işlevler
				dahil olmak üzere sosyal medya kullanmanız.
			</p>
		</div>
	);
};

export default Index;
