import React, { useEffect } from "react";
import homescfourimg from "../../assets/homescfourimg.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Sectionfour = () => {
	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);
	return (
		<div data-aos="fade-up" className="bg-sectionfourbackground pt-16 pb- motion-safe:animate-fadeIn">
			<div className="md:flex md:mx-20">
				<div className="md:mr-40 md:m-20 md:w-[90%] mx-8">
					<div className="">
						<h1 className="text-3xl font-extrabold text-sectionthreeheader">
							UYKUSUZ BİR PİYASAYI TAKİP ETMEK YORUCU OLABİLİR.
						</h1>
					</div>
					<p className=" mt-3 text-base font-medium">
						Para, asla uyumaz diyor ve piyasanın nabzını sizin için 7/24 kontrol ediyoruz. Uzman ekibimizle beraber
						yatırımınızın en başından, gelecek yıllara kadar size bir yatırım partneri oluşturuyoruz.
					</p>

					<button className="bg-gradient-to-r from-fromdark to-tolight hover:bg-gradient-to-l p-4 mt-6 text-white font-semibold rounded-md">
						Demo Hesap Olustur
					</button>
				</div>
				<div data-aos="fade-left" className="flex justify-center items-center text-center mt-10">
					<img className="float-right w-[90%]" src={homescfourimg} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Sectionfour;
